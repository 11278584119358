<template lang="pug">
  v-container#pettycash(fluid='' tag='section' style="padding: 0px;")
    v-dialog(v-model='dialogUpdateBalance' width='500')
      v-card
        v-card-title.text-h5.grey.lighten-2
          | Actualizar Valor de Caja Chica
        v-card-text
          v-col.py-0(cols='12' md='12' style="margin-top: 7px;")
            v-text-field(@keypress="isNumber($event)" style="margin-top: 15px;" v-model='valBalanceUpdate' :rules="requeridos" label='Valor Caja Chica' type='text' maxlength="150")
        v-divider
        v-card-actions
          v-spacer
          v-btn(color='red' text='' @click='dialogUpdateBalance = false')
            | Cancelar
          v-spacer
          v-btn(color='primary' text='' @click='updateBalanceVal()')
            | Actualizar
    v-dialog(v-model='dialogEntry' max-width='500')
      v-card
        v-card-title.text-h5
          h4 Agregar Modificar Ingreso
        v-card-text(style='padding-right:0; padding-bottom:0;')
          v-form(ref='formAddEntry' v-model='validEntry' lazy-validation='' style="padding-top: 10px;")
            v-row(style="width: 100%;")
              v-col(cols="8" md="8")
                v-menu(ref='menu1' v-model='menu1' :close-on-content-click='false' :return-value.sync='entry.date' transition='scale-transition' offset-y='' min-width='290px')
                  template(v-slot:activator='{ on }')
                    v-text-field(readonly v-model='entry.date' label='Fecha' prepend-icon='mdi-calendar' v-on='on' disabled)
                  v-date-picker(v-model='entry.date' no-title='' scrollable='' disabled style='margin:0px;' color="green lighten-1")
                    v-spacer
                    v-btn(text='' color='primary' @click='menu1 = false') Cancelar
                    v-btn.success-btn(text='' @click='$refs.menu1.save(entry.date)') Listo
              v-col(cols="4" md="4")
                v-text-field( :rules='[rules.required]' label='Monto' v-model="entry.amount" type="text" @keypress="isNumber($event)" :disabled="entry.id")
              v-col(cols="12" md="12")
                v-text-field( label='Número de Factura' v-model="entry.invoiceNumber" type="text" :disabled="entry.id")
              v-col(cols="12" md="12")
                v-text-field( :rules='[rules.required]' label='Motivo' v-model="entry.motivo" type="text" :disabled="entry.id")
              v-col(cols="12")
                v-textarea( :rules='[rules.required]' solo='' label='Descripción' height='70' v-model="entry.description" :disabled="entry.id")
        v-card-actions
          v-spacer
          v-btn(color='red darken-1' text='' @click='dialogEntry = false')
            | CANCELAR
          v-btn.success-btn(text='' @click='saveEntry' :disabled="entry.id" :loading="loadingSaveEntry")
            | GUARDAR
    v-dialog(v-model='dialogEgress' max-width='500')
      v-card
        v-card-title.text-h5
          h4 Agregar Modificar Egreso
        v-card-text(style='padding-right:0; padding-bottom:0;')
          v-form(ref='formAddEgress' v-model='validEntry' lazy-validation='' style="padding-top: 10px;")
            v-row(style="width: 100%;")
              v-col(cols="8" md="8")
                v-menu(ref='menu2' v-model='menu2' :close-on-content-click='false' :return-value.sync='egress.date' transition='scale-transition' offset-y='' min-width='290px')
                  template(v-slot:activator='{ on }')
                    v-text-field(readonly v-model='egress.date' label='Fecha' prepend-icon='mdi-calendar' v-on='on' disabled)
                  v-date-picker(v-model='egress.date' no-title='' scrollable='' disabled style='margin:0px;' color="green lighten-1")
                    v-spacer
                    v-btn(text='' color='primary' @click='menu2 = false') Cancelar
                    v-btn.success-btn(text='' @click='$refs.menu1.save(egress.date)') Listo
              v-col(cols="4" md="4")
                v-text-field( :rules='[rules.required]' label='Monto' v-model="egress.amount" type="text" @keypress="isNumber($event)" :disabled="egress.id")
              v-col(cols="12" md="12")
                v-text-field( label='Número de Factura' v-model="egress.invoiceNumber" type="text" :disabled="egress.id")
              v-col(cols="12" md="12")
                v-text-field( :rules='[rules.required]' label='Motivo' v-model="egress.motivo" type="text" :disabled="egress.id")
              v-col(cols="12")
                v-textarea( :rules='[rules.required]' solo='' label='Descripción' height='70' v-model="egress.description" :disabled="egress.id")
        v-card-actions
          v-spacer
          v-btn(color='red darken-1' text='' @click='dialogEgress = false')
            | CANCELAR
          v-btn.success-btn(text='' @click='saveEgress' :disabled="egress.id" :loading="loadingSaveEgress")
            | GUARDAR
    v-snackbar(:timeout='5000' top='' right='' :color='snackbar.color' v-model='snackbar.show')
      | {{ snackbar.text }}
      v-btn(dark='' @click.native='snackbar.show = false' icon='' style='background: transparent;')
        v-icon mdi-close
    v-card
      v-row(style='margin:0px;')
        v-col(cols='12' md='12' style="padding: 0px;")
          v-card-text(style='padding-top:0;')
            v-row(style="max-width: 100%;")
              v-col(cols='10' md='6')
                v-row(style="margin-top: 10px;")
                  h2(style="padding-left: 30px; padding-top: 10px;")
                    | SALDO: ${{balance.toFixed(2)}}
                  v-tooltip(bottom='')
                    template(v-slot:activator='{ attrs, on }')
                      v-btn(v-on="on" color='blue' icon='' @click='dialogUpdateBalance = true' style='margin-top: 10px; font-size:20px; background: transparent;')
                        v-icon(style='font-size:45px;')
                          | mdi-update
                    span.tooltips Actualizar Valor de Caja Chica
            v-container#process-table(fluid='' tag='section' style='padding: 0px 0px 10px 22px;')
              v-row(style='display:flex; justify-content:center; max-width: 100%;')
                v-col(cols="12" md="4" style="padding: 0px 0px 10px 22px;")
                  v-menu(ref='menuFrom' v-model='menuFrom' :close-on-content-click='false' :return-value.sync='menuFrom' transition='scale-transition' offset-y='' min-width='290px')
                    template(v-slot:activator='{ on }')
                      v-text-field(readonly v-model='dateFrom' label='Buscar Desde' prepend-icon='mdi-calendar' v-on='on')
                    v-date-picker(@change='$refs.menuFrom.save(dateFrom)' v-model='dateFrom' no-title='' scrollable='' :max="dateUntil" style='margin:0px;' color="green lighten-1" :min="minDateForConsults")
                v-col(cols="10" md="4" style="padding: 0px 0px 10px 22px;")
                  v-menu(ref='menuUntil' v-model='menuUntil' :close-on-content-click='false' :return-value.sync='dateUntil' transition='scale-transition' offset-y='' min-width='290px')
                    template(v-slot:activator='{ on }')
                      v-text-field(readonly v-model='dateUntil' label='Buscar Hasta' prepend-icon='mdi-calendar' v-on='on')
                    v-date-picker(@change='$refs.menuUntil.save(dateUntil)' v-model='dateUntil' no-title='' scrollable='' :min="dateFrom" style='margin:0px;' color="green lighten-1")
                v-col(cols="2" style="padding: 0px 0px 10px 22px;")
                  v-tooltip(bottom='')
                    template(v-slot:activator='{ attrs, on }')
                      v-btn(v-on="on" icon='' color='gray' style="background-color: RGB(1, 125, 71);" @click="filterByDate")
                        v-icon(style="font-size: 30px; color: white;") mdi-magnify
                    span.tooltips Buscar
      v-row(style="max-width: 100%;")
        v-col(cols='12' md='6' style="padding: 0px 0px 10px 22px; display:flex; justify-content:center;")
          v-card(style="margin-top: 0px; margin-left:15px;" elevation='4')
            v-card-text
              v-row(style="max-width: 100%;")
                v-col(cols='7' style='display:flex; justify-content:flex-end; padding:0px;')
                  .text-h3.font-weight-light
                    h3 INGRESOS
                v-col(cols='5' style='display:flex; justify-content:flex-end; padding:0px;')
                  v-tooltip(bottom='')
                    template(v-slot:activator='{ attrs, on }')
                      v-btn(v-on="on" color='primary' icon='' @click='newEntry' style='font-size:20px; background: transparent;')
                        v-icon(style='font-size:45px;')
                          | mdi-plus-circle
                    span.tooltips Crear Ingreso
                  v-tooltip(bottom='')
                    template(v-slot:activator='{ attrs, on }')
                      v-btn(v-on="on" icon='' style='background: transparent;' color='secondary' @click='downloadCSVFileIngresos' :loading="loadingDownloadEntry")
                        v-icon(style="font-size:45px;") mdi-arrow-down-bold-box
                    span.tooltips Descargar Lista de Ingresos
                v-container#process-table(fluid='' tag='section' style='padding: 0px 0px 10px 22px; margin-top: 20px;')
                  v-row(style='height: 40vh; max-width: 100%;')
                    v-col(cols='12' md='12' style='padding:0px; margin:0px; overflow: auto')
                      v-simple-table(style='height: 100px;' scrollable='')
                        thead
                          tr
                            th.primary--text
                              | Fecha
                            //th.primary--text
                              | Hora
                            th.primary--text
                              | Descripción
                            th.primary--text
                              | Monto
                            th.primary--text
                              | Borrar
                        tbody
                          tr(v-for='(entry, index) in entryLst' :key='index')
                            td(style="cursor: pointer;" @click="viewEntry(entry)")
                              | {{ entry.date }}
                            //td(style="cursor: pointer;" @click="viewEntry(entry)")
                              | {{ entry.created }}
                            td(style="cursor: pointer;" @click="viewEntry(entry)")
                              | {{ entry.description }}
                            td(style="cursor: pointer;" @click="viewEntry(entry)")
                              | $ {{ parseFloat(entry.amount).toFixed(2) }}
                            td.text-center(style='cursor: pointer;')
                              v-btn(icon='' style='background: transparent;' color='red' small='' @click="deleteEntry(entry)" :loading="loadingDeleteRegister" :disabled="entry.documentId && entry.documentId !== '' ? true : false")
                                v-icon mdi-delete
                v-col(cols="12" md="6" offset-md="6")
                  h3 Total: ${{totalIngreso.toFixed(2)}}
        v-col(cols='12' md='6' style="padding: 0px 0px 10px 32px; display:flex; justify-content:center;")
          v-card(style="margin-top: 0px; margin-right:15px;" elevation='4')
            v-card-text
              v-row(style="max-width: 100%;")
                v-col(cols='7' style='display:flex; justify-content:flex-end; padding:0px;')
                  .text-h3.font-weight-light
                    h3 EGRESOS
                v-col(cols='5' style='display:flex; justify-content:flex-end; padding:0px;')
                  v-tooltip(bottom='')
                    template(v-slot:activator='{ attrs, on }')
                      v-btn(v-on="on" color='primary' icon='' @click='newEgress' style='font-size:20px; background: transparent;')
                        v-icon(style='font-size:45px;')
                          | mdi-plus-circle
                    span.tooltips Crear Egreso
                  v-tooltip(bottom='')
                    template(v-slot:activator='{ attrs, on }')
                      v-btn(v-on="on" icon='' style='background: transparent;' color='secondary' @click='downloadCSVFileEgresos' :loading="loadingDownloadEgress")
                        v-icon(style="font-size: 45px;") mdi-arrow-down-bold-box
                    span.tooltips Descargar Lista de Egresos
                v-container#process-table(fluid='' tag='section' style='padding: 0px 0px 10px 22px; margin-top: 20px;')
                  v-row(style='height: 40vh; max-width: 100%;')
                    v-col(cols='12' md='12' style='padding:0px; margin:0px; overflow: auto')
                      v-simple-table(style='height: 100px;' scrollable='')
                        thead
                          tr
                            th.primary--text
                              | Fecha
                            //th.primary--text
                              | Hora
                            th.primary--text
                              | Descripción
                            th.primary--text
                              | Monto
                            th.primary--text
                              | Borrar
                        tbody
                          tr(v-for='(egress, index) in egressLst' :key='index')
                            td(style="cursor: pointer;" @click="viewEgress(egress)")
                              | {{ egress.date }}
                            //td(style="cursor: pointer;" @click="viewEgress(egress)")
                              | {{ egress.created }}
                            td(style="cursor: pointer;" @click="viewEgress(egress)")
                              | {{ egress.description }}
                            td(style="cursor: pointer;" @click="viewEgress(egress)")
                              | $ {{ parseFloat(egress.amount).toFixed(2) }}
                            td.text-center(style='cursor: pointer;')
                              v-btn(icon='' style='background: transparent;' color='red' small='' @click="deleteEgress(egress)" :loading="loadingDeleteEgressRegister" :disabled="egress.documentId && egress.documentId !== '' ? true : false")
                                v-icon mdi-delete
                v-col(cols="12" md="6" offset-md="6")
                  h3 Total: ${{totalEgreso.toFixed(2)}}
</template>
<script>
  import moment from 'moment'
  import * as functions from '../../../functions.js'
  export default {
    data: () => ({
      minDateForConsults: moment().subtract(15, 'days').format('YYYY-MM-DD'),
      valBalanceUpdate: 0.00,
      dialogUpdateBalance: false,
      loadingDownloadEgress: false,
      loadingDownloadEntry: false,
      loadingDeleteRegister: false,
      loadingDeleteEgressRegister: false,
      rules: {
        required: value => !!value || 'Requerido',
        min: v => v.length >= 6 || 'Mínimo 6 caracteres',
      },
      validEntry: true,
      validEgress: true,
      balance: 0.00,
      loadingSaveEntry: false,
      loadingSaveEgress: false,
      dialogEntry: false,
      dialogEgress: false,
      entry: {
        date: moment(new Date()).format('YYYY-MM-DD'),
        created: '',
        description: '',
        amount: '',
        motivo: '',
        invoiceNumber: '',
      },
      egress: {
        date: moment(new Date()).format('YYYY-MM-DD'),
        created: '',
        description: '',
        amount: '',
        motivo: '',
        invoiceNumber: '',
      },
      snackbar: {
        show: false,
        text: '',
        color: '',
      },
      menu1: false,
      menu2: false,
      menuUntil: false,
      menuFrom: false,
      dateUntil: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).toISOString().substr(0, 10),
      dateFrom: moment(new Date()).format('YYYY-MM-DD'),
    }),
    computed: {
      plan () {
        return this.$store.state.suscriptionPlan.plan
      },
      pettycash () {
        return this.$store.state.pettycash.pettycash
      },
      entryLst () {
        return this.$store.state.pettycash.pettycashEntryLst
      },
      totalIngreso () {
        return parseFloat(this.$store.state.pettycash.totalEntryVal)
      },
      egressLst () {
        return this.$store.state.pettycash.pettycashEgressLst
      },
      totalEgreso () {
        return parseFloat(this.$store.state.pettycash.totalEgressVal)
      },
    },
    watch: {
      plan () {
        if (this.plan.diasConsulta) {
          this.minDateForConsults = moment().subtract(this.plan.diasConsulta, 'days').format('YYYY-MM-DD')
        }
      },
      pettycash () {
        if (this.pettycash) {
          if (this.pettycash.balance) {
            this.balance = parseFloat(this.pettycash.balance)
            this.valBalanceUpdate = parseFloat(this.pettycash.balance)
          } else {
            this.balance = 0.00
            this.valBalanceUpdate = 0.00
          }
        } else {
          this.balance = 0.00
          this.valBalanceUpdate = 0.00
        }
      },
    },
    methods: {
      updateBalanceVal () {
        if (this.valBalanceUpdate.toString() === '') {
          this.snackbar = {
            show: true,
            color: 'red',
            text: 'Para continuar debe agregar el valor de caja chica nuevo.',
          }
          return
        }
        this.$store.dispatch('pettycash/setBalance', this.valBalanceUpdate).then(async resolve => {
          this.snackbar = {
            show: true,
            color: 'green',
            text: 'Actualización realizada de manera correcta.',
          }
          this.dialogUpdateBalance = false
        }, reject => {
          this.dialogUpdateBalance = false
          this.snackbar = {
            show: true,
            color: 'red',
            text: 'Error dentro de la actualización.',
          }
        })
      },
      downloadCSVFileIngresos () {
        this.loadingDownloadEntry = true
        const data = []
        for (let i = 0; i < this.entryLst.length; i++) {
          data.push({
            Fecha: this.entryLst[i].date,
            Descripcion: this.entryLst[i].description,
            Monto: this.entryLst[i].amount.replace('.', ','),
          })
        }
        functions.JSONToCSVConvertor(data, 'Ingresos-Caja-Chica', true)
        this.loadingDownloadEntry = false
      },
      downloadCSVFileEgresos () {
        this.loadingDownloadEgress = true
        const data = []
        for (let i = 0; i < this.egressLst.length; i++) {
          data.push({
            Fecha: this.egressLst[i].date,
            Descripcion: this.egressLst[i].description,
            Monto: '-' + this.egressLst[i].amount.replace('.', ','),
          })
        }
        functions.JSONToCSVConvertor(data, 'Egresos-Caja-Chica', true)
        this.loadingDownloadEgress = false
      },
      saveEntry () {
        if (this.$refs.formAddEntry.validate()) {
          this.loadingSaveEntry = true
          this.$store.dispatch('pettycash/saveNewPettyCashEntry', this.entry).then(res => {
            this.loadingSaveEntry = false
            if (res) {
              this.dialogEntry = false
              this.snackbar = {
                show: true,
                color: 'green',
                text: 'Ingreso agregado de manera correcta.',
              }
            } else {
              this.snackbar = {
                show: true,
                color: 'red',
                text: 'Error al momento de agregar el ingreso.',
              }
            }
          }).catch(e => {
            this.loadingSaveEntry = false
            this.snackbar = {
              show: true,
              color: 'red',
              text: 'Error al momento de agregar el ingreso.',
            }
          })
        }
      },
      saveEgress () {
        if (this.$refs.formAddEgress.validate()) {
          this.loadingSaveEgress = true
          this.$store.dispatch('pettycash/saveNewPettyCahsEgress', this.egress).then(res => {
            this.loadingSaveEgress = false
            if (res) {
              this.dialogEgress = false
              this.snackbar = {
                show: true,
                color: 'green',
                text: 'Egreso agregado de manera correcta.',
              }
            } else {
              this.snackbar = {
                show: true,
                color: 'red',
                text: 'Error al momento de agregar el egreso.',
              }
            }
          }).catch(e => {
            this.loadingSaveEgress = false
            this.snackbar = {
              show: true,
              color: 'red',
              text: 'Error al momento de agregar el egreso.',
            }
          })
        }
      },
      filterByDate () {
        this.$store.dispatch('pettycash/loadEntryArrFilterDate', [this.dateFrom, this.dateUntil])
        this.$store.dispatch('pettycash/loadEgressArrFilterDate', [this.dateFrom, this.dateUntil])
      },
      newEntry () {
        this.dialogEntry = true
      },
      viewEntry (item) {
        this.entry = item
        this.dialogEntry = true
      },
      deleteEntry (item) {
        this.loadingDeleteRegister = true
        this.$store.dispatch('pettycash/deletePettyCashEntry', item).then(res => {
          this.loadingDeleteRegister = false
          if (res) {
            this.snackbar = {
              show: true,
              color: 'green',
              text: 'Egreso eliminado de manera correcta.',
            }
            this.filterByDate()
          } else {
            this.snackbar = {
              show: true,
              color: 'red',
              text: 'Error al momento de eliminar el egreso.',
            }
          }
        }).catch(e => {
          this.loadingDeleteRegister = false
          this.snackbar = {
            show: true,
            color: 'red',
            text: 'Error al momento de eliminar el egreso.',
          }
        })
      },
      newEgress () {
        this.dialogEgress = true
      },
      viewEgress (item) {
        this.egress = item
        this.dialogEgress = true
      },
      deleteEgress (item) {
        this.loadingDeleteEgressRegister = true
        this.$store.dispatch('pettycash/deletePettyCahsEgress', item).then(res => {
          this.loadingDeleteEgressRegister = false
          if (res) {
            this.snackbar = {
              show: true,
              color: 'green',
              text: 'Egreso eliminado de manera correcta.',
            }
            this.filterByDate()
          } else {
            this.snackbar = {
              show: true,
              color: 'red',
              text: 'Error al momento de eliminar el egreso.',
            }
          }
        }).catch(e => {
          this.loadingDeleteEgressRegister = false
          this.snackbar = {
            show: true,
            color: 'red',
            text: 'Error al momento de eliminar el egreso.',
          }
        })
      },
      isNumber (evt) {
        evt = (evt) || window.event
        var charCode = (evt.which) ? evt.which : evt.keyCode
        if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
          evt.preventDefault()
        } else {
          return true
        }
      },
    },
    mounted () {
      if (this.plan.diasConsulta) {
        this.minDateForConsults = moment().subtract(this.plan.diasConsulta, 'days').format('YYYY-MM-DD')
      }
      if (this.pettycash) {
        if (this.pettycash.balance) {
          this.balance = parseFloat(this.pettycash.balance)
        } else {
          this.balance = 0.00
        }
      } else {
        this.balance = 0.00
      }
      this.filterByDate()
    },
  }
</script>
